// sort Nominativ first and Dativ second to keep the convention that the first two morphemes identify the pronoun set
const cases = ['n', 'd', 'a', 'g'] as const;

const morphemeByCase = <M extends string>(morphemeType: M): `${M}_${typeof cases[number]}`[] => {
    return cases.map((caseAbbreviation) => `${morphemeType}_${caseAbbreviation}` as const);
};

export default [
    ...morphemeByCase('pronoun'),
    ...morphemeByCase('possessive_determiner_f'),
    ...morphemeByCase('possessive_determiner_m'),
    ...morphemeByCase('possessive_determiner_n'),
    ...morphemeByCase('possessive_determiner_x'),
    ...morphemeByCase('possessive_determiner_pl'),
    ...morphemeByCase('possessive_pronoun_f'),
    ...morphemeByCase('possessive_pronoun_m'),
    ...morphemeByCase('possessive_pronoun_n'),
    ...morphemeByCase('possessive_pronoun_x'),
    ...morphemeByCase('possessive_pronoun_pl'),
    ...morphemeByCase('relative'),
    ...morphemeByCase('demonstrative'),
    'pronoun_equal',
    'possessive_pronoun_substantivized',
    'adverb_because',
    'adverb_back_then',
    'adverb_by',
    'adjective_back_then',
] as const;
