import routerOptions0 from "/home/admin/www/pronomen.net/release/20241212143045/node_modules/.pnpm/nuxt@3.14.159_@parcel+watcher@2.4.1_@types+node@20.16.5_encoding@0.1.13_e_ajvjrf6u7hodtogoukobrbe67u/node_modules/nuxt/dist/pages/runtime/router.options.js";
import routerOptions1 from "/home/admin/www/pronomen.net/release/20241212143045/app/router.options.ts";
const configRouterOptions = {
  hashMode: false,
  scrollBehaviorType: "auto"
}
export default {
...configRouterOptions,
...routerOptions0,
...routerOptions1,
}