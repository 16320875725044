import { default as abuseReportsLk1lJpAUrNMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/abuseReports.vue?macro=true";
import { default as _91id_93IE0i2Tc1YUMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/audit-log/[username]/[id].vue?macro=true";
import { default as indexcS4NxmZkH8Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/index.vue?macro=true";
import { default as moderationKHcYWhyMVfMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/moderation.vue?macro=true";
import { default as pendingBansBKDTzBq7crMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/pendingBans.vue?macro=true";
import { default as profilesP30pQjOqYcMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/profiles.vue?macro=true";
import { default as expensesmJGsDbxPqRMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/expenses.vue?macro=true";
import { default as indexUEUauZM4OfMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/index.vue?macro=true";
import { default as overviewETEAKwuC8VMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/overview.vue?macro=true";
import { default as awaitinggcRunv9jTEMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/translations/awaiting.vue?macro=true";
import { default as missingxlFYxrgPncMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/translations/missing.vue?macro=true";
import { default as usersWoz0R1o4BcMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/admin/users.vue?macro=true";
import { default as apipPNZNYjCh6Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/api.vue?macro=true";
import { default as _91slug_93QVjwLa2JFeMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/blog/[slug].vue?macro=true";
import { default as indexF98IDzYuNLMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/blog/index.vue?macro=true";
import { default as _91_91year_93_93nTWhZjDG4GMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/calendar/[[year]].vue?macro=true";
import { default as _91year_93_45_91month_93_45_91day_93UV7ourfCW4Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/calendar/[year]-[month]-[day].vue?macro=true";
import { default as adminASXaCfWKxYMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/census/admin.vue?macro=true";
import { default as indexcJaXJSPbxyMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/census/index.vue?macro=true";
import { default as contactBC4hzj1GbLMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/contact.vue?macro=true";
import { default as designWUkW5LRI8sMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/design.vue?macro=true";
import { default as englishLnyqNX8mS5Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/english.vue?macro=true";
import { default as faqSCDMFYWPKaMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/faq.vue?macro=true";
import { default as inclusivesgyo8h7s1GMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/inclusive.vue?macro=true";
import { default as indexKw7ILvBBGsMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/index.vue?macro=true";
import { default as license7rMBU0TbUPMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/license.vue?macro=true";
import { default as academicnisLFjCUMmMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/links/academic.vue?macro=true";
import { default as indexrGkbkPHuTVMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/links/index.vue?macro=true";
import { default as mediaSuJKbGyMqxMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/links/media.vue?macro=true";
import { default as translinguisticsa78NZfnfXxMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/links/translinguistics.vue?macro=true";
import { default as zinehdswfonEFNMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/links/zine.vue?macro=true";
import { default as namesonkcFSSx4sMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/names.vue?macro=true";
import { default as indexqNzsz5BTsSMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/nouns/index.vue?macro=true";
import { default as templates1qccDobawAMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/nouns/templates.vue?macro=true";
import { default as peopleXPMbrnJGL8Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/people.vue?macro=true";
import { default as privacyx877B0SqU6Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/privacy.vue?macro=true";
import { default as _91username_93yMdmMnFL2QMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/profile/[username].vue?macro=true";
import { default as card_45_91username_93Edi4fJCuGrMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/profile/card-[username].vue?macro=true";
import { default as editorC8XZwiXVnjMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/profile/editor.vue?macro=true";
import { default as anyILSOk2leovMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/any.vue?macro=true";
import { default as askuUQBPM0ukEMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/ask.vue?macro=true";
import { default as avoidingSSjwvgYlq9Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/avoiding.vue?macro=true";
import { default as indexPC96Pio8DvMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/index.vue?macro=true";
import { default as mirrorhIdpR6jTAkMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/mirror.vue?macro=true";
import { default as pronounFSBUcKNJ86Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/pronoun.vue?macro=true";
import { default as sources5UmErSkukaMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/sources.vue?macro=true";
import { default as teambIJcTS9Ky6Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/team.vue?macro=true";
import { default as terminologyT8wBMCnchVMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/terminology.vue?macro=true";
import { default as termsws0sQl9TQNMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/terms.vue?macro=true";
import { default as usercCoudKfb0yMeta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/user.vue?macro=true";
import { default as workshopsNLo2xALXU2Meta } from "/home/admin/www/pronomen.net/release/20241212143045/pages/workshops.vue?macro=true";
import { default as deklinationsmusterHoOdm86sCDMeta } from "~/data/nouns/deklinationsmuster.vue?macro=true";
export default [
  {
    name: "admin-abuseReports",
    path: "/admin/abuse-reports",
    meta: abuseReportsLk1lJpAUrNMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/abuseReports.vue")
  },
  {
    name: "admin-audit-log-username-id",
    path: "/admin/audit-log/:username()/:id()",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/audit-log/[username]/[id].vue")
  },
  {
    name: "admin",
    path: "/admin",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/index.vue")
  },
  {
    name: "admin-moderation",
    path: "/admin/moderation",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/moderation.vue")
  },
  {
    name: "admin-pendingBans",
    path: "/admin/pending-bans",
    meta: pendingBansBKDTzBq7crMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/pendingBans.vue")
  },
  {
    name: "admin-profiles",
    path: "/admin/profiles",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/profiles.vue")
  },
  {
    name: "admin-timesheets-expenses",
    path: "/admin/timesheets/expenses",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/expenses.vue")
  },
  {
    name: "admin-timesheets",
    path: "/admin/timesheets",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/index.vue")
  },
  {
    name: "admin-timesheets-overview",
    path: "/admin/timesheets/overview",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/timesheets/overview.vue")
  },
  {
    name: "admin-translations-awaiting",
    path: "/admin/translations/awaiting",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/translations/awaiting.vue")
  },
  {
    name: "admin-translations-missing",
    path: "/admin/translations/missing",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/translations/missing.vue")
  },
  {
    name: "admin-users",
    path: "/admin/users",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/admin/users.vue")
  },
  {
    name: "api",
    path: "/api",
    meta: apipPNZNYjCh6Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/api.vue")
  },
  {
    name: "blog-slug",
    path: "/blog/:slug()",
    meta: _91slug_93QVjwLa2JFeMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/blog/[slug].vue")
  },
  {
    name: "blog",
    path: "/blog",
    meta: indexF98IDzYuNLMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/blog/index.vue")
  },
  {
    name: "calendar",
    path: "/calendar/:year?",
    meta: _91_91year_93_93nTWhZjDG4GMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/calendar/[[year]].vue")
  },
  {
    name: "calendarDay",
    path: "/calendar/:year()-:month()-:day()",
    meta: _91year_93_45_91month_93_45_91day_93UV7ourfCW4Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/calendar/[year]-[month]-[day].vue")
  },
  {
    name: "census-admin",
    path: "/census/admin",
    meta: adminASXaCfWKxYMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/census/admin.vue")
  },
  {
    name: "census",
    path: "/census",
    meta: indexcJaXJSPbxyMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/census/index.vue")
  },
  {
    name: "contact",
    path: "/contact",
    meta: contactBC4hzj1GbLMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/contact.vue")
  },
  {
    name: "design",
    path: "/design",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/design.vue")
  },
  {
    name: "english",
    path: "/english",
    meta: englishLnyqNX8mS5Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/english.vue")
  },
  {
    name: "faq",
    path: "/faq",
    meta: faqSCDMFYWPKaMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/faq.vue")
  },
  {
    name: "inclusive",
    path: "/inclusive",
    meta: inclusivesgyo8h7s1GMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/inclusive.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/index.vue")
  },
  {
    name: "license",
    path: "/license",
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/license.vue")
  },
  {
    name: "links-academic",
    path: "/links/academic",
    meta: academicnisLFjCUMmMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/links/academic.vue")
  },
  {
    name: "links",
    path: "/links",
    meta: indexrGkbkPHuTVMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/links/index.vue")
  },
  {
    name: "links-media",
    path: "/links/media",
    meta: mediaSuJKbGyMqxMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/links/media.vue")
  },
  {
    name: "links-translinguistics",
    path: "/links/translinguistics",
    meta: translinguisticsa78NZfnfXxMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/links/translinguistics.vue")
  },
  {
    name: "links-zine",
    path: "/links/zine",
    meta: zinehdswfonEFNMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/links/zine.vue")
  },
  {
    name: "names",
    path: "/names",
    meta: namesonkcFSSx4sMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/names.vue")
  },
  {
    name: "nouns",
    path: "/nouns",
    meta: indexqNzsz5BTsSMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/nouns/index.vue")
  },
  {
    name: "nouns-templates",
    path: "/nouns/templates",
    meta: templates1qccDobawAMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/nouns/templates.vue")
  },
  {
    name: "people",
    path: "/people",
    meta: peopleXPMbrnJGL8Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/people.vue")
  },
  {
    name: "privacy",
    path: "/privacy",
    meta: privacyx877B0SqU6Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/privacy.vue")
  },
  {
    name: "profile-username",
    path: "/profile/:username()",
    meta: _91username_93yMdmMnFL2QMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/profile/[username].vue")
  },
  {
    name: "profile-card-username",
    path: "/profile/card-:username()",
    meta: card_45_91username_93Edi4fJCuGrMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/profile/card-[username].vue")
  },
  {
    name: "profile-editor",
    path: "/profile/editor",
    meta: editorC8XZwiXVnjMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/profile/editor.vue")
  },
  {
    name: "pronouns-any",
    path: "/pronouns/any",
    meta: anyILSOk2leovMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/any.vue")
  },
  {
    name: "pronouns-ask",
    path: "/pronouns/ask",
    meta: askuUQBPM0ukEMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/ask.vue")
  },
  {
    name: "pronouns-avoiding",
    path: "/pronouns/avoiding",
    meta: avoidingSSjwvgYlq9Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/avoiding.vue")
  },
  {
    name: "pronouns",
    path: "/pronouns",
    meta: indexPC96Pio8DvMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/index.vue")
  },
  {
    name: "pronouns-mirror",
    path: "/pronouns/mirror",
    meta: mirrorhIdpR6jTAkMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/mirror.vue")
  },
  {
    name: "all",
    path: "/:path(.*)",
    meta: pronounFSBUcKNJ86Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/pronouns/pronoun.vue")
  },
  {
    name: "sources",
    path: "/sources",
    meta: sources5UmErSkukaMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/sources.vue")
  },
  {
    name: "team",
    path: "/team",
    meta: teambIJcTS9Ky6Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/team.vue")
  },
  {
    name: "terminology",
    path: "/terminology",
    meta: terminologyT8wBMCnchVMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/terminology.vue")
  },
  {
    name: "terms",
    path: "/terms",
    meta: termsws0sQl9TQNMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/terms.vue")
  },
  {
    name: "user",
    path: "/user",
    meta: usercCoudKfb0yMeta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/user.vue")
  },
  {
    name: "workshops",
    path: "/workshops",
    meta: workshopsNLo2xALXU2Meta || {},
    component: () => import("/home/admin/www/pronomen.net/release/20241212143045/pages/workshops.vue")
  },
  {
    name: "nouns-deklinationsmuster",
    path: "/deklinationsmuster",
    component: () => import("~/data/nouns/deklinationsmuster.vue")
  }
]